.icon-container {
  padding: 8px;
  border-radius: 30%;
}

.icon-hover:hover {
  background-color: #f0f0f0;
}

.ant-picker {
  padding: 3px;
  border: 1px solid #6a6a6a;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-picker .ant-picker-input input {
  display: none;
}

.ant-picker-input {
  padding: 4px 2px;
}

.ant-picker-input input {
  color: #989898;
  padding: 2px 10px;
}

.mobile_order_detail_card .ant-picker-input input {
  display: flex !important;
}

.ant-picker-suffix {
  color: #6a6a6a;
  margin-left: 1px !important;
}

.order_detail_card .ant-card-body {
  padding: 0px !important;
}

.orders .ant-table-wrapper .ant-table-thead > tr > th {
  font-size: 16px !important;
}

.orders_page {
  margin-bottom: 12px;
  gap: 8px;
}

.customButton:hover {
  color: black !important;
}

@media (min-width: 768px) {
  .orders_searchbar input {
    background-color: #f6f6f6;
  }

  .ant-picker {
    padding: 4px 10px;
    border: none;
  }

  .ant-picker-input {
    display: flex !important;
    justify-content: space-between;
    gap: 20px;
  }

  .ant-picker-input input {
    display: flex !important;
  }

  .order_detail_date .ant-picker {
    padding: 0px;
  }
}

.sort_conatiner article {
  cursor: pointer;
  color: #6a6a6a;
  padding: 10px 12px;
}

.sort_conatiner article:hover {
  background-color: #eeeffa;
  color: #13188a;
  font-weight: 600;
}

:where(.css-dev-only-do-not-override-9ntgx0).ant-picker
  .ant-picker-input
  > input:placeholder-shown {
  display: none;
}

:where(.css-dev-only-do-not-override-9ntgx0).ant-picker {
  height: 32px;
}

.text-truncate-single-line {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all; /* Breaks long words */
  overflow-wrap: break-word;
}

.text-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  max-height: 3em;
}

.text-truncate > span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  max-height: 3em;
  word-break: break-all;
  overflow-wrap: break-word;
}

@media (min-width: 770px) {
  :where(.css-dev-only-do-not-override-9ntgx0).ant-picker {
    height: 36px;
  }
}
