.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-light
  .ant-menu-item:hover:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ) {
  color: white;
  font-weight: bold;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
  border-width: 2px;
  border-bottom-color: white;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after {
  border-width: 2px;
  border-bottom-color: white;
}

.anticon svg {
  display: block;
}

.ant-menu-title-content div {
  color: gray;
}

.ant-input-affix-wrapper input {
  background: #f6f6f6;
}
.inventory-search input {
  background: white !important;
}
.login_btn button {
  padding: 2px 10px;
}
.ant-avatar {
  background-color: white;
}
.anticon-shopping-cart {
  color: #6a6a6a;
}
.anticon-user {
  color: #6a6a6a;
}

.header-search {
  border-radius: 10px;
  box-shadow: 0px 0px 5px darkgrey;
}
.header-category > .ant-select-selector {
  height: 100% !important;
}
input[type='search']::-webkit-search-cancel-button {
  display: none;
}

.ant-row.relative {
  flex-flow: unset;
}

.ant-col.logo {
  max-width: 100%;
  .logo-image {
    max-width: 170px;
  }
}
