@tailwind base;
@tailwind components;
@tailwind utilities;

.pointer-hover:hover {
  cursor: pointer;
}

* {
  padding: 0;
  margin: 0;
  background-color: '#ffffff';
  font-family: 'Montserrat', sans-serif;
}

body {
  background-color: white;
}

.ant-layout {
  background-color: white;
}

body::-webkit-scrollbar {
  display: none;
}
body {
  scrollbar-width: none;
}

.ant-table
  .ant-table-container
  .ant-table-content
  table
  thead.ant-table-thead
  .ant-table-cell {
  background-color: rgba(24, 30, 172, 0.1);
}

.ant-table-thead .ant-table-cell {
  background-color: #e9e9e9 !important;
  font-weight: 600 !important;
}

.ant-table-row .ant-table-cell {
  border: none !important;
}

/* .ant-table-row .ant-table-cell p{
  color: #202020 !important;
} */

.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 10px rgb(197, 197, 196);
  border-color: #c5c5c4;
}

.ant-checkbox-wrapper
  + :where(.css-dev-only-do-not-override-9ntgx0).ant-checkbox-wrapper {
  margin-inline-start: 0;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #181eac !important;
}

.ant-tabs .ant-tabs-tab:hover {
  color: #101481 !important;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #181eac !important;
  pointer-events: none;
}

.ant-select-single.ant-select-sm:not(
    .ant-select-customize-input
  ).ant-select-show-arrow
  .ant-select-selection-item {
  color: #4e4d4b;
  font-size: 13px;
}

.custom-select-dropdown .ant-select-item-option-content {
  white-space: normal; /* Allow text wrapping */
}

.ant-select .ant-select-arrow {
  color: #4e4d4b;
}

.ant-btn-primary {
  background-color: #181eac;
}

.ant-btn-primary:not(:disabled):hover {
  background-color: #101481;
}

.ant-btn-default:not(:disabled):hover {
  color: #fff;
}

.ant-form-item {
  margin-bottom: 0px;
}

.ant-upload-wrapper .ant-upload-select {
  display: block;
}

.ant-input-number .ant-input-number-input {
  text-align: center;
}

.ant-input:hover {
  border-color: #181eac;
}

:where(.css-dev-only-do-not-override-9ntgx0).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #181eac;
}

.ant-pagination-item-active {
  background-color: #181eac !important;
}

.ant-pagination-item-active a {
  color: white !important;
}

.ant-table-cell-row-hover {
  background-color: #eeeffa !important;
}

.ant-table-filter-column {
  display: flex !important;
  justify-content: flex-start !important;
}

.ant-table-filter-column .ant-table-column-title {
  flex-grow: 0 !important;
}

.ant-pagination-prev {
  border: 1px solid #f1f1f1;
}

.ant-pagination-prev .ant-pagination-item-link {
  color: #000000 !important;
}

.ant-pagination-next {
  border: 1px solid #f1f1f1;
}

.ant-pagination-next .ant-pagination-item-link {
  color: #000000 !important;
}

:where(.css-dev-only-do-not-override-9ntgx0).ant-table-wrapper
  .ant-table-thead
  > tr
  > th {
  font-size: 16px;
}
:where(.css-dev-only-do-not-override-9ntgx0).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  font-size: 13px;
  font-weight: 500;
}

:where(.css-9ntgx0).ant-checkbox-wrapper
  + :where(.css-9ntgx0).ant-checkbox-wrapper {
  margin-inline-start: 0px;
}

.register-btn span {
  color: #202020 !important;
}

.Forget_back span {
  color: #13188a !important;
}

.ant-table-header {
  border-radius: 6px 6px 0px 0px;
  height: 56px;
}

.ant-table-cell::before {
  display: none;
}

/* Hover effect */
.ant-table-cell-row-hover {
  background-color: #eeeffa !important;
}

.ant-table-cell-row {
  border-radius: 0px;
  background-color: white;
}

/* .ant-table-tbody tr > td { */
:ant-table-row:hover {
  background: #eeeffa !important;
}

/* Hover effect */
.ant-table-tbody tr:hover {
  background-color: #eeeffa;
  /* Set your desired hover background color */
}

.ant-table-tbody tr {
  background-color: white;
  border-bottom: none !important;
}

.ant-table-tbody tr:active {
  background-color: #eeeffa;
}

.ant-table-tbody tr:focus {
  background-color: #eeeffa;
}

.ant-table {
  overflow: auto;

  border: 1px solid #e9e9e9 !important;

  background-color: white !important;
}

.ant-table-body::-webkit-scrollbar {
  display: none;
}

.ant-table-body {
  scrollbar-width: none;
}

.ant-table-tbody {
  border-bottom: none;
}

.ant-table-measure-row + tr > td {
  border-top: none !important;
}

.product_detail .control-dots .dot {
  width: 7px !important;
  height: 7px !important;
  border-radius: 100% !important;
}

/* .prodcut_detail .carousel .control-prev {
  position: absolute;
} */

.product_detail .carousel .control-dots .dot.selected {
  background-color: #202020;
}

.product_detail .carousel .control-dots .dot {
  background-color: #989898;
}

.product_detail .carousel .control-arrow {
  height: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  top: 90%;
}

.product_detail .carousel .control-arrow.control-prev {
  display: none;
}

.product_detail .carousel .control-arrow.control-next {
  display: none;
}

.product_detail .carousel .control-arrow.control-prev::before {
  border-right: none;
}

.product_detail .carousel .control-arrow.control-next::before {
  border-left: none;
}

.ant-tabs .ant-tabs-tab-btn {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #989898;
}

.product_detail .anticon-plus svg {
  width: 25px;
  height: 36px;
  color: black;
}

.product_detail .anticon-minus svg {
  width: 20px;
  height: 36px;
  color: black;
}

@media screen and (min-width: 780px) {
  .product_detail .carousel .control-arrow.control-prev {
    display: block;
    left: 5%;
    top: 70%;
    background: url('../images/resources/Backward.png');
  }

  .product_detail .carousel .control-arrow.control-next {
    display: block;
    right: 5%;
    top: 70%;
    background: url('../images/resources/Forward.png');
  }
}

.hide-Scroll::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}
.hide-Scroll {
  scrollbar-width: none;
}

.orders_responsive_cards {
  width: 100%;
}

.store .ant-tabs .ant-tabs-tab {
  font-size: 20px !important;
}

.store .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-size: 20px !important;
  font-weight: 600;
  color: #13188a;
}

.store .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 20px !important;
  font-weight: 500;
  color: #6a6a6a;
}

.ant-table-body {
  margin-top: 5px;
  margin-bottom: 10px;
}
.orders_responsive_cards .ant-spin-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

@media screen and (max-width: 780px) {
  .product_detail .carousel .control-dots {
    bottom: 10% !important;
  }
}

.head .ant-table-thead .ant-table-cell {
  background: white !important;
}

.ant-form-item .ant-form-item-label {
  padding-bottom: 4px !important;
}

.inventory_modal .ant-input {
  height: 42px !important;
}

.inventory_modal .ant-input-number .ant-input-number-input {
  text-align: left !important;
  height: 42px !important;
}

.ant-select-selector {
  height: 42px !important;
  align-items: center;
}

.transfer_modal .ant-select-selector {
  height: auto !important;
  align-items: center;
}

.ant-select-selection-search {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 780px) {
  .store .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 16px !important;
    font-weight: 600;
    color: #13188a;
  }

  .store .ant-tabs-tab.ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 16px !important;
    font-weight: 500;
    color: #6a6a6a;
  }
  .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin-right: 6px !important;
  }
}

@media screen and (min-width: 976px) and (max-width: 1200px) {
  .inventoryCard {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}

.inventory_card .ant-input-number .ant-input-number-input {
  text-align: left;
  height: 42px;
}

.cart .ant-table-container th {
  background-color: white !important;
}

.cart .ant-table-header {
  height: 100% !important;
}

.cart .ant-table-wrapper .ant-table.ant-table-middle {
  border-radius: 6px 6px 0px 0px !important;
}
.ant-menu-light {
  color: white !important;
}
:where(.css-9ntgx0).ant-menu-horizontal {
  border-bottom: 0px;
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Standard syntax */
}

.items-select .ant-select-selector {
  background-color: #181eac !important;
  color: white !important;
}

.items-select .ant-select-selector:hover {
  background-color: #101481 !important;
}

.items-select.ant-select-open .ant-select-selection-item {
  color: white !important;
}

.items-select .ant-select-arrow {
  color: white;
}
