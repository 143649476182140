.container {
  max-width: 1280px;
  padding: 1rem;
  margin: auto;
}

.tileWrapper {
  padding: 0.5rem;
  flex: 1; /* Allow flex items to grow and shrink */
  min-width: 300px; /* Minimum width for a tile */
  max-width: 90%; /* Full width on mobile screens */
}

.tile {
  background-color: #e2e8f0;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  text-align: center;
  margin: auto;
}

.statistics-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.subtitle {
  color: #4a5568;
}
